import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // baseUrl: 'http://www.cuida.com',  //本地环境
        // baseUrl: 'http://test.ruilubao.com',  //测试环境
        baseUrl:'http://notice.ruilubao.com',  //正式环境
        // baseUrl: 'http://qianbao.kejiplat.cn',//测试环境 ，正式数据
        reg: {
            phone: /^1\\d{10}$/,
            email: /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
            license: /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g
        },
        login_type: 0,
        logininformation: {},
        reCode: '',//数据留存单号
        loginEquipment: '',//保存设备状态，0是电脑，1是手机
    },
    mutations: {},
    actions: {},
    modules: {}
})
