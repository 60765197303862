import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Vueaxios from 'vue-axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/iconfont/iconfont.css'

Vue.use(Vueaxios, axios);
Vue.use(ElementUI);
Vue.prototype.$axios = axios;
Vue.prototype.store = store;
Vue.config.productionTip = false
router.afterEach((to) => {
    window.scrollTo(0,0); //路由转跳后滚动到顶部
    if (to.meta.title) {//title
        document.title = to.meta.title
        document.querySelector('meta[name="Keywords"]').setAttribute('content', to.meta.keywords)
        document.querySelector('meta[name="Description"]').setAttribute('content',to.meta.description)
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
