<template>
    <div class="home">
        <router-view></router-view>
    </div>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'front',
    }
</script>
<style lang="scss" scoped>
</style>
